import React from "react";
import "./Marquee.css"; // Import CSS for animations
import testmonial1 from "../../studenttest/saimanohar.jpg";
import testmonial2 from "../../studenttest/baradwaj.jpg";
import testmonial3 from "../../studenttest/rajeev.jpg";
import testmonial4 from "../../studenttest/venkatesh.jpg";
import testmonial5 from "../../studenttest/narendar.jpg";
import saketh from "../../studenttest/saketh.jpg";
import akash from "../../studenttest/akash.jpg";
import sumanth from "../../studenttest/sumanth.jpg";
import manikanta from "../../studenttest/manikanta.jpg";

const reviews = [
  {
    name: "Rajeev",
    username: "Senior Digital Marketing Expert",
    body: "Hashpro academy",
    img: testmonial3,
  },
  {
    name: "Sai Manohar",
    username: "Founder",
    body: "Digital Searches",
    img: testmonial1,
  },
  {
    name: "Bharadwaj",
    username: "Founder",
    body: "Digital Searches",
    img: testmonial2,
  },
  {
    name: "M Venkateshwarlu",
    username: "Social media manager",
    body: "Doch india pvt ltd",
    img: testmonial4,
  },
  {
    name: "Narender",
    username: "Digital Marketing Manager",
    body: "Mylands Group LLP",
    img: testmonial5,
  },
  {
    name: "Saket",
    username: "Digital Marketing Intern",
    body: "FHO - Marketing Agency",
    img: saketh,
  },
  {
    name: "Sumanth Vaddala",
    username: "Digital Marketing Specialist",
    body: "Ubicor Capital",
    img: sumanth,
  },
  {
    name: "Akash",
    username: "Digital Marketing Associate",
    body: "Marketing Agency",
    img: akash,
  },
  {
    name: "Manikanta",
    username: "Digital marketing intern",
    body: "Volta",
    img: manikanta,
  },
];

const firstColumn = reviews.slice(0, reviews.length / 2);
const secondColumn = reviews.slice(reviews.length / 2);

const ReviewCard = ({ img, name, username, body }) => {
  return (
    <figure className="review-card">
      <div className="review-header">
        <img className="avatar" src={img} alt={name} />
      </div>
      <div className="review-body">
        <figcaption className="name">{name}</figcaption>
        <p className="username">{username}</p>
        <blockquote className="review-text">{body}</blockquote>
      </div>
    </figure>
  );
};

const VerticalMarquee = ({ children, reverse }) => {
  return (
    <div className={`marquee-vertical-container ${reverse ? "reverse" : ""}`}>
      <div className="marquee-vertical-content">{children}</div>
      <div className="marquee-vertical-content">{children}</div>
    </div>
  );
};

export default function VerticalMarqueeDemo() {
  return (
    <div className="marquee-vertical-wrapper">
      <VerticalMarquee>
        {firstColumn.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </VerticalMarquee>

      <VerticalMarquee reverse id="reverseStudentMarquee">
        {secondColumn.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </VerticalMarquee>
    </div>
  );
}
