import { useEffect, useLayoutEffect, useState } from "react";
import "./aoi.css";
import gsap, { ScrollTrigger } from "gsap/all";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "../../Global_Components/Loader";
import { Helmet } from "react-helmet";
import FAQSection from "./FAQSection";

export default function AOI() {
  const [formStatus, setFormStatus] = useState("default");

  const [utmSource, setUtmSource] = useState("Website");
  const [subSource, setSubSource] = useState("Website");

  const getUTMParameter = (name) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  };

  useEffect(() => {
    const source = getUTMParameter("utm_source");
    if (source) {
      setUtmSource(source);
    }

    const sub = getUTMParameter("sub_source");
    if (sub) {
      setSubSource(sub);
    }
  }, []);

  useLayoutEffect(() => {
    let httpRequest;
    gsap.registerPlugin(ScrollTrigger);
    let mm = gsap.matchMedia();

    gsap.to(".scroll-marquee ", {
      scrollTrigger: {
        trigger: ".scroll-marquee",
        scrub: true,
        start: "-800px 20%",
        end: "800px 50%",
      },
      marginLeft: "-1000px",
    });

    gsap.to(".who .ball-container", {
      scrollTrigger: {
        trigger: ".who .blocks-container",
        scrub: true,
        start: "top 50%",
        end: "bottom 50%",
      },
      ease: "linear",
      top: "100%",
    });

    // const questionss = document.querySelectorAll(".question-containerr");

    // questionss.forEach((question) => {
    //   question.addEventListener("click", () => {
    //     if (question.classList.contains("collapsed")) {
    //       question.classList.add("show");
    //       question.classList.remove("collapsed");
    //       question.style.height = "fit-content";
    //       question.querySelector(".question img").style.transform = "rotate(180deg)";
    //     } else {
    //       question.classList.add("collapsed");
    //       question.classList.remove("show");
    //       question.classList.height = "0px";
    //       question.querySelector(".question img").style.transform = "rotate(0deg)";
    //     }
    //   });
    // });

    //Form Submission
    const submitBtn = document.getElementById("submit-btn");
    submitBtn.addEventListener("submit", function () {});

    // Custom Error Message

    const form = document.getElementById("aoi-form");

    const inputs = form.querySelectorAll("input");

    submitBtn.addEventListener("submit", function () {
      inputs.forEach((input) => {
        if (!input.checkValidity()) {
          input.setCustomValidity(input.title);
        }
      });
    });

    const formEl = document.getElementById("form-tag");
    formEl.addEventListener("submit", function (e) {
      e.preventDefault();

      const formData = {};

      // Collect input values
      formEl.querySelectorAll("input, textarea, select").forEach((input) => {
        console.log(input);
        const inputType = input.type.toLowerCase();
        const inputName = input.name;
        const inputValue =
          inputType === "checkbox" ? input.checked : input.value;

        if (inputType === "radio") {
          if (input.checked) {
            formData[inputName] = inputValue;
          }
        } else if (inputType === "select-one") {
          formData[inputName] = input.value;
        } else {
          console.log(inputName, inputValue);
          formData[inputName] = inputValue;
        }
      });

      // Convert the JSON object to a string
      const jsonData = JSON.stringify(formData);

      // Log the JSON data to the console
      console.log(jsonData);
    });

    gsap.to(".aoi-hero .text .face-container", {
      delay: "1",
      duration: "0.5",
      opacity: 1,
      transform: "translateY(0px)",
    });
  });

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    coverLetter: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z][a-zA-Z ]*$/, "You cannot enter symbols")
      .required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Please enter a valid 10-digit mobile number")
      .required("Phone number is required"),
    location: Yup.string().required("Location is required"),
  });
  const handleSubmit = (values, { setSubmitting }) => {
    const finalValues = {
      ...values,
      utm_source: utmSource,
      sub_source: subSource,
    };

    setFormStatus("loading");
    const jsonData = JSON.stringify(finalValues);

    fetch(`${process.env.REACT_APP_SERVER_URL}/forms/aoi`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: jsonData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setFormStatus("submitted");
        window.location.replace("/thank-you/aoi");
        return response.text();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
        setFormStatus("failed");
      })
      .finally(() => {
        setSubmitting(false);
      });

    console.log(jsonData);
  };

  return (
    <>
      <Helmet>
        <title>{"Art Of Influence"}</title>
        <meta name="description" content="Art Of Influence" />
        <meta property="og:title" content="Your Open Graph Title" />
        <meta property="og:description" content="Your Open Graph Description" />
        <meta property="og:image" content="URL to Your Image" />
        <meta property="og:url" content="URL to Your Web Page" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Your Site Name" />
        <meta property="og:locale" content="en_US" />
        <meta name="twitter:title" content="Your Twitter Title" />
        <meta name="twitter:description" content="Your Twitter Description" />
        <meta name="twitter:image" content="URL to Your Image" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="aoi-page">
        <section id="hero" className="aoi-hero">
          <div className="text">

            <div className="face-container ">
              <img loading="eager" src="/global/aoi/img/Art-Of_influence-Hashproacaemy.webp" />
              <div className="vertical-flex">
                <div className="center">
                  <h1 className="heading">
                    ART OF
                    <br />{" "}
                    <span style={{ fontFamily: "inherit" }} className="yellow ">
                      INFLUENCE
                    </span>
                  </h1>
                  <h6 className="subheading">
                    {" "}
                    <i>
                      {" "}
                      Discover the <b> New You</b> with <b> Art of Influence</b>
                    </i>{" "}
                  </h6>
                  <a href="#admissions">
                    <button id="aoi-btn">Enquire Now !</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="aoi-form" className="aoi-form">
          <div id="admissions" />
          <div className="grid-container">
            <div className="aoi-form-text">
              <div>
                <h2 className="heading">Your Future is One Step Away</h2>
                <h4 className="subheading"></h4>
                <div className="para-container">
                  <h6 className="para">
                    A <span className="bold-italic yellow"> 60+ days </span>
                    intensive program to transform your skills in{" "}
                    <span className="bold-italic yellow"> leadership</span>,
                    <span className="bold-italic yellow">
                      {" "}
                      experiencing startup ecosystem
                    </span>
                    ,{" "}
                    <span className="bold-italic yellow">
                      {" "}
                      entrepreneurial skills
                    </span>
                    , and{" "}
                    <span className="bold-italic yellow">
                      {" "}
                      overall personality development
                    </span>
                    , paving the way for a successful future.
                  </h6>
                </div>
              </div>
            </div>
            <div className="aoi-form-container">
              <Formik
                initialValues={{ name: "", email: "", phone: "", location: "" }}
                validationSchema={validationSchema}
                onSubmit={(values, actions) =>
                  handleSubmit(values, { ...actions, setStatus: setFormStatus })
                }
              >
                {({ isSubmitting }) => (
                  <Form id="form-tag">
                    <div className="input-container">
                      <Field
                        id="name"
                        type="text"
                        placeholder="Enter Your Name"
                        name="name"
                        className="text-black"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div className="input-container">
                      <Field
                        id="email"
                        type="email"
                        placeholder="Enter Your E-Mail"
                        name="email"
                        className="text-black"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div className="input-container">
                      <Field
                        id="phone"
                        type="text"
                        placeholder="Enter Your Phone No. "
                        name="phone"
                        className="text-black"
                      />
                      <span className=" text-yellow-500 p-2 m-2">
                        {" "}
                        *Preferably WhatsApp Number
                      </span>
                      <ErrorMessage
                        name="phone"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div className="input-container">
                      <Field
                        id="location"
                        type="text"
                        placeholder="Enter Your Location"
                        name="location"
                        className="text-black"
                      />
                      <ErrorMessage
                        name="location"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div>
                      <Field
                        type="hidden"
                        name="utm_source"
                        value={utmSource}
                      />
                    </div>

                    <button
                      id="submit-btn"
                      type="submit"
                      disabled={
                        isSubmitting ||
                        formStatus == "loading" ||
                        formStatus == "submitted"
                      }
                    >
                      {isSubmitting ? (
                        <Loader />
                      ) : formStatus === "failed" ? (
                        "Failed, Submit Again"
                      ) : formStatus === "submitted" ? (
                        "Form Has Been Submitted"
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </section>
        <section className="aoi-video">
          <div id="what" />
          <h2>
            <span className="slalom-font">What is </span>Art Of Influence ?
          </h2>
          <div className="grid-container">
            {/*- <div class="video-container">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/AjWfY7SnMBI"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
      </div>*/}
            <div className="points-container">
              {/*<h3>Program Highlights</h3>*/}
              <button>
                <div>
                  {" "}
                  A transformative{" "}
                  <span className="bold-italic">60+ Days Program</span>
                </div>
              </button>
              <br />
              <div className="points">
                <div className="point">
                  <img src="/global/aoi/img/why/Classroom-At-Hashproacademy.webp" />
                  <p>
                    {" "}
                    <span className="">
                      Classroom-based training program{" "}
                    </span>{" "}
                    tailored for learning outcomes
                  </p>
                </div>
                <div className="point">
                  <img src="/global/aoi/img/why/Interactions-At-Hashproacademy.webp" />
                  <p>
                    Dynamic engagement through hands-on activities and
                    interactive methods
                  </p>
                </div>
                <div className="point">
                  <img src="/global/aoi/img/why/AOI-badge-Hashproacademy.webp" />
                  <p>Gain practical insights from Industry Experts</p>
                </div>
                <div className="point">
                  <img src="/global/aoi/img/why/Lecture-Hashprocademy.webp" />
                  <p>Exclusive guest lectures within the program</p>
                </div>
                <div className="point">
                  <img src="/global/aoi/img/why/Lecture-Hashprocademy.webp" />
                  <p>
                    Explore unparalleled insights with a top-tier industry visit
                    experience
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="scroll-marquee">
          <div className="words-container">
            <h1>Art Of Influence</h1>
            <h1>Art Of Influence</h1>
            <h1>Art Of Influence</h1>
            <h1>Art Of Influence</h1>
            <h1>Art Of Influence</h1>
            <h1>Art Of Influence</h1>
            <h1>Art Of Influence</h1>
            <h1>Art Of Influence</h1>
            <h1>Art Of Influence</h1>
            <h1>Art Of Influence</h1>
            <h1>Art Of Influence</h1>
            <h1>Art Of Influence</h1>
            <h1>Art Of Influence</h1>
            <h1>Art Of Influence</h1>
          </div>
        </section>
        <section className="who">
          <div id="art-of-influence" />
          <h2>Who is this Program For?</h2>
          <div className="blocks-container">
            <div className="line-container"></div>
            <div className="ball-container" />
            <div className="block one">
              <div className="text">
                <h4 className="heading">1. Aspiring Entrepreneurs</h4>
                <p>
                  Harness the tools of success in entrepreneurship, develop
                  essential skills, nurture a resilient mindset, and cultivate
                  unwavering confidence to conquer the challenges of the
                  business world
                </p>
              </div>
              <img src="/global/aoi/img/who/Aspiring-Entrepreneurs.png" />
            </div>
            <div className="block two">
              <img src="/global/aoi/img/who/Professionals-Seeking-Advancement.png" />
              <div className="text">
                <h4 className="heading">
                  2. Professionals Seeking Advancement
                </h4>
                <p>
                  Individuals who are looking to progress in their careers,
                  improve their leadership and management abilities and
                  cultivate a competitive edge in their respective fields.
                </p>
              </div>
            </div>
            <div className="block three">
              <div className="text">
                <h4 className="heading">3. Students and Recent Graduates</h4>
                <p>
                  Those who want to supplement their academic education with
                  practical skills and a mindset geared towards success in their
                  future endeavors.
                </p>
              </div>
              <img src="/global/aoi/img/who/3Students and-Recent-Graduates.png" />
            </div>
            <div className="block four">
              <img src="/global/aoi/img/who/Professionals-in-Transition.png" />
              <div className="text">
                <h4 className="heading">4. Professionals in Transition</h4>
                <p>
                  Individuals who are going through career transitions or
                  seeking to explore new opportunities and want to equip
                  themselves with the necessary skills and mindset for success
                  in their new ventures
                </p>
              </div>
            </div>
            <div className="block five">
              <div className="text">
                <h4 className="heading">
                  5. Individuals Seeking Personal Growth
                </h4>
                <p>
                  Those who are motivated to enhance themselves, develop
                  self-awareness and improve their ability to influence and
                  inspire others.
                </p>
              </div>
              <img src="/global/aoi/img/who/Individuals-Seeking-PersonalGrowth.png" />
            </div>
          </div>
        </section>
        <section className="trainers">
          <h2>Get Trained From The Best</h2>
          <Splide
            className="trainer-splide"
            options={{
              autoplay: true,
              interval: 2000,
              pagination: false,
              rewind: true,
              // isNavigation: true,
              breakpoints: {},
            }}
          >
            <SplideSlide>
              <div className="trainer">
                <div className="pic-container">
                  <img src="/global/images/people/Soumya-trainer-hashproacademy.webp" />
                </div>
                <div className="about">
                  <h3 className="name">Mrs Soumya Vijay Kandi</h3>
                  <p className="role">Certified Corporate Trainer</p>
                  <p className="text">
                    Soumya Vijay, a seasoned trainer for over a decade, sets a
                    benchmark with her HR leadership, compelling communication,
                    and commitment. Known as the 'favorite trainer' at Hashpro
                    Academy for her focus on individual upskilling.
                  </p>
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="trainer">
                <div className="pic-container">
                  <img src="/global/images/people/Vamshi-kurapati-Co-founder-of-hashproacademy.webp" />
                </div>
                <div className="about">
                  <h3 className="name">Mr Vamshi Kurapati</h3>
                  <p className="role">Founder</p>
                  <p className="text">
                    Vamshi, a visionary leader, (co)founded two businesses,
                    mentors young entrepreneurs, and conducts insightful
                    interviews. Committed to empowering youth with valuable
                    skills, his impact is significant and poised for continued
                    success.
                  </p>
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="trainer">
                <div className="pic-container">
                  <img src="/global/images/people/Ananth-faculty-hashproacademy.webp" />
                </div>
                <div className="about">
                  <h3 className="name text-white">Mr Ananth Ram Gadepalli</h3>
                  <p className="role">
                    Head of Sales, Ex-Bjyus &amp; White hat
                  </p>
                  <p className="text">
                    Ananth Ram, a sales professional in ed-tech, brings passion
                    and expertise in technology trends to transform learners and
                    educators. His insights are invaluable assets
                    for organizations.
                  </p>
                </div>
              </div>
            </SplideSlide>
          </Splide>

          {/* <div className="btn-container">
                        <img
                            id="aoi-prev-btn"
                            className="splide-btn"
                            src="/global/aoi/icons/icons8-next-page-50.png"
                        />
                        <img
                            id="aoi-next-btn"
                            className="splide-btn"
                            src="/global/aoi/icons/icons8-next-page-50.png"
                        />
                    </div> */}
        </section>
        <section className="aoi-cta">
          <h2>
            Unlocking Influence for Personal <br /> &amp; <br /> Professional
            Mastery
          </h2>
          {/* <p>Small 2 lines about course</p> */}
          {/* <p>Aliquip occaecat laboris velit esse velit esse nulla sit.</p> */}
          <a href="#admissions">
            {" "}
            <button className="mt-4">Seize your spot now !</button>
          </a>
        </section>
        <section className="steps">
          <h2 className="heading">Application Process</h2>
          <div className="text-container">
            <p className="emoji">📝</p>
            <p>
              Fill The <span className="white"> Application Form</span>{" "}
            </p>
            <h2 className="one">1</h2>
          </div>
          <div className="text-container">
            <p className="emoji">💬</p>
            <p>
              Reserve Your Spot For Personal Clarity Session{" "}
              <span className="white" forwri="">
                (PCS){" "}
              </span>
            </p>
            <h2>2</h2>
          </div>
          <div className="text-container">
            <p className="emoji">🏢</p>
            <p>
              Visit The <span className="white"> Academy</span>{" "}
            </p>
            <h2>3</h2>
          </div>
          <div className="text-container">
            <p className="emoji">🙌🏻</p>
            <p>
              Enrollment <span className="white">&amp;</span> Payment{" "}
            </p>
            <h2>4</h2>
          </div>
        </section>
        <section className="faq">
          <h2>FAQ's</h2>
          {/* <div className="questionss">
            <div className="question-containerr collapsed">
              <h3 className="question">
                <p>Is this course available online?</p>
                <img src="/global/icons/faq-arrow.webp" />
              </h3>
              <p className="answer">
                No, our course is entirely conducted offline at our Academy
                located in Himayat Nagar.
              </p>
            </div>
            <div className="question-containerr collapsed">
              <h3 className="question">
                What is the duration of each class?
                <img src="/global/icons/faq-arrow.webp" />
              </h3>
              <p className="answer">
                Each class has a duration of 150 - 180 minutes. Classes are held
                from Monday to Saturday.
              </p>
            </div>
            <div className="question-containerr collapsed">
              <h3 className="question">
                Do you offer job assistance?
                <img src="/global/icons/faq-arrow.webp" />
              </h3>
              <p className="answer">
                Our program primarily focuses on skills enhancement and
                entrepreneurship skill development, preparing individuals for
                personal and professional growth.
              </p>
            </div>
            <div className="question-containerr collapsed">
              <h3 className="question">
                Is there an EMI Option available for payment?
                <img src="/global/icons/faq-arrow.webp" />
              </h3>
              <p className="answer">
                Yes, we offer the flexibility to choose the EMI option for
                payment.
              </p>
            </div>
            <div className="question-containerr collapsed">
              <h3 className="question">
                Is any commitment required for the course?
                <img src="/global/icons/faq-arrow.webp" />
              </h3>
              <p className="answer">
                Yes, participants are required to commit to the program for the
                entire 35+ days duration without missing any classes.
              </p>
            </div>
            <div className="question-containerr collapsed">
              <h3 className="question">
                Who can benefit from this course?
                <img src="/global/icons/faq-arrow.webp" />
              </h3>
              <p className="answer">
                This program is designed to benefit a diverse audience,
                including students, business enthusiasts, startup founders,
                graduates, and individuals from various educational backgrounds.
              </p>
            </div>
          </div> */}
          {/* 
          new compo */}

          <FAQSection />
        </section>
      </div>
    </>
  );
}
