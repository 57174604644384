import "./App.css";
import Placement from "./pages/Placement/Placement";
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import "./Global_Components/global_scripts";

import AddPlacement from "./pages/Placement/Admin/AddPlacement";
import ManagePlacement from "./pages/Placement/Admin/ManagePlacement";
import EditPlacement from "./pages/Placement/Admin/EditPlacement";
import Navbar from "./pages/Placement/Admin/Navbar";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useLayoutEffect, useState } from "react";
import Signin from "./pages/Placement/Signin";
import StudentRoute from "./pages/Placement/Routes/StudentRoute";
import AdminRoute from "./pages/Placement/Routes/AdminRoute";
import ManageEmails from "./pages/Placement/Admin/ManageEmails";
import MailOperations from "./pages/Placement/Admin/MailOperations";
import Loader from "./Global_Components/Loader";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import StudentsLife from "./pages/StudentsLife/StudentsLife";
import DMX from "./pages/DMX/DMX";
import Header from "./Global_Components/Header";
import Footer from "./Global_Components/Footer";
import AOI from "./pages/AOI/AOI";
import DME from "./pages/DME/DME";
import ScrollToTop from "./Global_Components/ScrollToTop";
import Contact from "./pages/Contact/Contact";
import AddJob from "./pages/Careers/AddJob";
import JD from "./pages/Careers/JD";
import Compare from "./pages/Compare/Compare";
import Payment from "./pages/Payments/Payment";
import Receipt from "./pages/Payments/Receipt";
import { AuthProvider } from "./Providers/AuthProvider";
import UserDashboard from "./pages/User/UserDashboard";
import UserPaymentHistory from "./pages/User/UserPaymentHistory";
import Cancelled from "./pages/Payments/Cancelled";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserHeader from "./Global_Components/UserHeader";
import ThankYou from "./pages/ThankYou/ThankYou";
import Details from "./pages/HAT/HATDetails";
import HATRules from "./pages/HAT/HATRules";
import HATTest from "./pages/HAT/HATTest";
import { TestProvider } from "./Providers/HATProvider";
import HATThankYou from "./pages/HAT/components/HATThankYou";
import HATDashboard from "./pages/HAT/HATDashboard";
import NotFound from "./pages/NotFound/NotFound";
import TermsAndConditions from "./pages/Policies/TermsAndConditions";
import CancellationAndRefundPolicy from "./pages/Policies/CancellationAndRefundPolicy";
import ShippingAndDeliveryPolicy from "./pages/Policies/ShippingAndDeliveryPolicy";
import PrivacyPolicy from "./pages/Policies/PrivacyPolicy";
import HDFCSignup from "./pages/HDFC/HDFCSignup";
import MasterClass from "./pages/MasterClass/MasterClass";

import RazorPayment from "./pages/Rozarpay/RazorPayment";
import { MasterClassProvider } from "./Providers/MasterclassProvider";
import HireFromUs from "./pages/HIRE FROM US/HireFromUs";

import MultiStepAoiform from "./pages/instaForms/MultiStepAoiform";
import MultiStepDmxform from "./pages/instaForms/MultiStepFormDmx";
import MultiStepFinanceForm from "./pages/instaForms/MultiStepFinanceFrom";
import InstaDmxThankyou from "./pages/instaForms/InstaDmxThankyou";
import InstaAoiThankyou from "./pages/instaForms/InstaAoiThankyou";
import InstaFinanceThankyou from "./pages/instaForms/InstaFinanceThankyou";
import BlogManagement from "./pages/blogs/BlogManagement";
import AllBlogs from "./pages/blogs/AllBlogs";
import Blog from "./pages/blogs/Blog";
import Leadboard from "./pages/leadboard/Leadboard";
import SalesDataComponent from "./pages/leadboard/SalesDataComponent";
import WhatsAppButton from "./Global_Components/WhatsAppButton";
import Congratulations from "./Global_Components/Congratulations";
import Demo from "./pages/DMX/Demo";

export default function App() {
  const [isLoggedin, setLoggedin] = useState(false);
  const [authLoaded, setLoadedAuth] = useState(false);

  const auth = getAuth();

  onAuthStateChanged(auth, (user) => {
    if (user != null) {
      console.log(user.email);
      setLoggedin(true);
      setLoadedAuth(true);
    } else {
      console.log("No User");
      setLoggedin(false);
      setLoadedAuth(true);
    }
  });

  return (
    <>
      <ToastContainer />

      <div className="transition" id="body-container">
        <MasterClassProvider>
          <BrowserRouter>
            <Header />

            <ScrollToTop />

            <Routes>
              <Route element={<JD />} path="/careers/business-development" />
              <Route element={<AddJob />} path="/careers/add" />
              <Route element={<Contact />} path="/contact" />
              <Route element={<About />} path="/about" />
              <Route element={<StudentsLife />} path="/students-life" />
              <Route element={<AOI />} path="/art-of-influence" />
              <Route
                element={<DMX />}
                path="/digital-marketing-xperential-bootcamp"
              />
              {/* <Route element={<DME />} path='/digital-marketing' /> */}
              {/* <Route element={<Compare />} path='/dme-vs-dmx' /> */}
              <Route element={<Signin />} path="/paynow" />
              <Route element={<ThankYou />} path="/thank-you" />
              <Route element={<ThankYou />} path="/thank-you/:from" />
              <Route element={<MasterClass />} path="/dmx-masterclass" />
              <Route element={<HireFromUs />} path="/hire-from-us" />

              <Route
                element={<TermsAndConditions />}
                path="/terms-and-conditions"
              />
              <Route
                element={<CancellationAndRefundPolicy />}
                path="/cancellation-and-refund-policy"
              />
              <Route
                element={<ShippingAndDeliveryPolicy />}
                path="/shipping-and-delivery-policy"
              />
              <Route element={<PrivacyPolicy />} path="/privacy-policy" />
              <Route element={<RazorPayment />} path="/razorpay" />

              <Route element={<Home />} path="/" />

              <Route element={<HDFCSignup />} path="/hdfc/signup" />

              <Route path="/instaAOIform" element={<MultiStepAoiform />} />
              <Route path="/instaDMXform" element={<MultiStepDmxform />} />
              <Route
                path="/instaFinanceform"
                element={<MultiStepFinanceForm />}
              />
              <Route path="/instaDMXthankyou" element={<InstaDmxThankyou />} />
              <Route path="/instaAOIthankyou" element={<InstaAoiThankyou />} />
              <Route
                path="/instaFinancethankyou"
                element={<InstaFinanceThankyou />}
              />
              <Route path="/blogmanagement" element={<BlogManagement />} />
              <Route path="/allblogs" element={<AllBlogs />} />
              <Route path="/congrats" element={<Congratulations />} />
              <Route path="/SalesDashboard" element={<SalesDataComponent />} />
              <Route path="/blog/:title" element={<Blog />} />
              <Route path="/demo" element={<Demo />} />
              {/* <Route path="/whatsapp" element={<WhatsAppButton />} /> */}
            </Routes>
            <TestProvider>
              <Routes>
                <Route path="/hat" element={<Details />} />
                <Route path="/hat/rules" element={<HATRules />} />
                <Route path="/hat/test/:section/:page" element={<HATTest />} />
                <Route path="/hat/thank-you" element={<HATThankYou />} />
                <Route path="/hat/dashboard" element={<HATDashboard />} />
              </Routes>
            </TestProvider>

            <WhatsAppButton />

            {!isLoggedin && authLoaded && <Footer />}
          </BrowserRouter>
        </MasterClassProvider>

        <AuthProvider>
          {!authLoaded ? (
            <Loader />
          ) : (
            isLoggedin &&
            authLoaded && (
              <>
                {/* <RouterProvider router={router} /> */}
                <BrowserRouter>
                  <Routes>
                    {/* <Route
                      path="/blogmanagement"
                      element={
                        <AdminRoute>
                          <Navbar />
                          <BlogManagement />
                        </AdminRoute>
                      }
                    /> */}
                    <Route
                      path="/placements/admin"
                      element={
                        <AdminRoute>
                          <Navbar />
                          <AddPlacement />
                        </AdminRoute>
                      }
                    />
                    <Route
                      path="/placements/admin/add"
                      element={
                        <AdminRoute>
                          <Navbar />
                          <AddPlacement />
                        </AdminRoute>
                      }
                    />
                    <Route
                      path="/placements/admin/manage"
                      element={
                        <AdminRoute>
                          <Navbar />
                          <ManagePlacement />
                        </AdminRoute>
                      }
                    />
                    <Route
                      path="/placements/admin/manage-students"
                      element={
                        <AdminRoute>
                          <Navbar />
                          <ManageEmails />
                        </AdminRoute>
                      }
                    />
                    <Route
                      path="/placements/admin/manage-students/manage-mail"
                      element={
                        <AdminRoute>
                          <Navbar />
                          <MailOperations />
                        </AdminRoute>
                      }
                    />
                    <Route
                      path="/placements/admin/edit"
                      element={
                        <AdminRoute>
                          <Navbar />
                          <EditPlacement />
                        </AdminRoute>
                      }
                    />

                    <Route
                      path="/placements"
                      element={
                        <StudentRoute>
                          <UserHeader />

                          <Placement />
                        </StudentRoute>
                      }
                    />
                    <Route
                      path="/user"
                      element={
                        <>
                          <UserHeader />
                          <UserDashboard />
                        </>
                      }
                    />
                    <Route
                      path="/user/payment-history"
                      element={
                        <>
                          {/* <UserHeader /> */}
                          <UserPaymentHistory />
                        </>
                      }
                    />

                    <Route
                      path="/payments"
                      element={
                        <>
                          <UserHeader />
                          <Payment />
                        </>
                      }
                    />
                    <Route
                      path="/payments/transaction/:orderid/:referenceno"
                      element={
                        <>
                          <UserHeader />
                          <Receipt />
                        </>
                      }
                    />
                    <Route
                      path="/payments/cancelled"
                      element={
                        <>
                          <UserHeader />
                          <Cancelled />
                        </>
                      }
                    />
                    <Route
                      path="/admin/addsales"
                      element={
                        <>
                          <Leadboard />
                        </>
                      }
                    />
                  </Routes>

                  <Footer />
                </BrowserRouter>
              </>
            )
          )}
        </AuthProvider>
      </div>

      <></>
    </>
  );
}
