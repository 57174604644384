import { useEffect, useLayoutEffect, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "./home.css";
import gsap, { ScrollToPlugin, ScrollTrigger } from "gsap/all";
import { Link } from "react-router-dom";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import AnimatedNumber from "react-animated-numbers";
import NumberCounter from "../../Global_Components/NumberCounter";
import NumberCounterDecimal from "../../Global_Components/NumberCounterDecimal";
import SplitType from "split-type";
import applyHeadingAnimations from "../../Global_Components/scripts/HeadingAnimation";
import { useGSAP } from "@gsap/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "../../Global_Components/Loader";
import { Helmet } from "react-helmet";

export default function Home() {
  const [formStatus, setFormStatus] = useState("default");

  const [numberOne, setNumberOne] = useState(0);
  const [numberTwo, setNumberTwo] = useState(0);

  useLayoutEffect(() => {
    setNumberOne(10);
    setNumberOne(400);

    function toggleShowClass() {
      const textboxes = document.querySelectorAll(".text-box");
      const randomIndexes = [];

      // Generate 5 unique random indexes
      while (randomIndexes.length < 5) {
        const randomIndex = Math.floor(Math.random() * textboxes.length);
        if (!randomIndexes.includes(randomIndex)) {
          randomIndexes.push(randomIndex);
        }
      }

      // Toggle the 'show' class for the selected text-boxes
      textboxes.forEach((box, index) => {
        const shouldShow = randomIndexes.includes(index);

        if (shouldShow && !box.classList.contains("show")) {
          // Add 'show' class if it doesn't have it
          box.classList.add("show");
        } else if (!shouldShow && box.classList.contains("show")) {
          // Remove 'show' class if it has it
          box.classList.remove("show");
        }
      });
    }

    // Run the function every 2 seconds
    setInterval(toggleShowClass, 2000);

    gsap.registerPlugin(ScrollTrigger);

    gsap.to(".usp-scroller.forward", {
      scrollTrigger: {
        trigger: ".usp-scroller-container",
        scrub: true,
        // markers: true,
        start: "-500% top",
        end: "bottom top",
        ease: "linear",
      },
      translateX: "-20%",
    });

    gsap.to(".usp-scroller.reverse", {
      scrollTrigger: {
        trigger: ".usp-scroller-container",
        scrub: true,
        // markers: true,
        start: "-500% top",
        end: "bottom top",
        ease: "linear",
      },
      translateX: "-100%",
    });

    gsap.to("#home-cta-span", {
      scrollTrigger: {
        trigger: "#home-cta",
        // markers: true,
        start: "center center",
        end: "bottom bottom",
        ease: "linear",
      },
      duration: "1",
      transform: "scaleX(1)",
    });

    const text = SplitType.create("#home-hero-text-anim");
    const lastRunTimestamp = localStorage.getItem("lastRunTimestamp");
    const now = Date.now();
    const thirtyMinutes = 30 * 60 * 1000;

    function runFinalPart() {
      console.log("half anim ");

      gsap.fromTo(
        text.chars,
        {
          y: 50,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          stagger: 0.03,
          duration: 1,
          ease: "power4.out",
          onComplete: () => {
            document
              .querySelector(".home-page .main-header .heading path")
              .classList.add("animate");
          },
        }
      );
    }

    function runFullAnimation() {
      console.log("fuull anim");
      document.querySelector(".home-page-loader").style.top = "0%";

      gsap
        .timeline()
        .to(".home-page-loader p", {
          delay: 0.5,
          duration: 1,
          stagger: 0.3,
          transform: "translateY(0)",
          opacity: 1,
        })
        .to(".home-page-loader", {
          delay: 0.5,
          duration: 0.5,
          top: "-120%",
        })
        .fromTo(
          text.chars,
          {
            y: 50,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            stagger: 0.03,
            duration: 1,
            ease: "power4.out",
            onComplete: () => {
              document
                .querySelector(".home-page .main-header .heading path")
                .classList.add("animate");
            },
          }
        );

      localStorage.setItem("lastRunTimestamp", now);
    }

    if (lastRunTimestamp) {
      if (now - lastRunTimestamp < thirtyMinutes) {
        console.log("under 30mins");

        runFinalPart(); //if under 30mins
      } else {
        console.log("more thean 30mins");

        runFullAnimation(); //if more than 30mins
      }
    } else {
      console.log(" lcoal storag not defuned");

      runFullAnimation(); //if local storage not found
    }

    // const courseCards = gsap.utils.toArray('.courses-container-2 .course-card');

    // // Apply the animation to each course card individually
    // courseCards.forEach((card) => {
    //     gsap.to(card, {

    //         scrollTrigger: {
    //             trigger: card,
    //             start: ' top',
    //             end: '200% center',
    //             markers: true,
    //             scrub: true
    //         },

    //         // opacity:
    //         scale: 0.8,
    //         opacity: 0.5
    //     });
    // });
  }, []);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    loaction: "",
    purpose: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z][a-zA-Z ]*$/, "You cannot enter symbols")
      .required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Please enter a valid 10-digit mobile number")
      .required("Phone number is required"),
    location: Yup.string()
      .min(4, "Location must be at least 4 characters")
      .required("Location is required"),
    purpose: Yup.string().required("Please Select An Option"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    setFormStatus("loading");
    const jsonData = JSON.stringify(values);

    fetch(`${process.env.REACT_APP_SERVER_URL}/forms/home`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: jsonData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setFormStatus("submitted");
        return response.text();
      })

      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
        setFormStatus("failed");
      })
      .finally(() => {
        setSubmitting(false);
      });

    console.log(jsonData);
  };

  return (
    <>
      <Helmet>
        <title>{"Hashpro Academy"}</title>
        <meta name="description" content="Hashpro Academy" />
        <meta property="og:title" content="Your Open Graph Title" />
        <meta property="og:description" content="Your Open Graph Description" />
        <meta property="og:image" content="URL to Your Image" />
        <meta property="og:url" content="URL to Your Web Page" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Your Site Name" />
        <meta property="og:locale" content="en_US" />
        <meta name="twitter:title" content="Your Twitter Title" />
        <meta name="twitter:description" content="Your Twitter Description" />
        <meta name="twitter:image" content="URL to Your Image" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div
        className="transition home-page-loader duration-1000 z-[9999] bg-black fixed top-[-120%] left-0 h-[100dvh] w-[100vw] "
        id="home-page-loader"
      >
        <div className="relative w-full h-full">
          <div className="flex flex-col text-center md:flex-row gap-6 z-[9999] justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <p className="translate-y-10 opacity-0 text-5xl font-light">
              {" "}
              HashPro
            </p>
            <p className="translate-y-10 opacity-0 text-5xl font-bold">
              {" "}
              Academy
            </p>
          </div>
        </div>
      </div>
      <div className="home-page ">
        <section
          style={{
            backgroundImage:
              "url(/global/decoration/transparent-grain-white.png)",
            backgroundSize: "220px,contain",
          }}
          className="     main-header pt-24 relative z-[999] overflow-x-hidden "
        >
          <div className="w-full h-full relative">
            <div className="absolute -top-[12%] md:top-[10%] -z-[1] right-0 w-[150px] h-[150px] hashpro-gradient-bg-1"></div>
            <div className="absolute top-[96%] md:top-1/2 -z-[1] left-0 w-[150px] h-[150px] hashpro-gradient-bg-1"></div>
          </div>
          <div className="absolute -top-[8%] -left-[33%] md:top-0 md:-left-[10%] -translate-x-40 w-[30rem] h-[30rem] rounded-[50%] border-[0.7rem] z-[0] border-solid border-hashpro opacity-[0.02] md:hidden"></div>
          <div className="absolute top-[20%] -right-[132%] md:-top-[10%] md:-right-[30%] -translate-x-40 w-[30rem] h-[30rem] rounded-[50%] border-[0.7rem] z-[0] border-solid border-hashpro opacity-[0.02]"></div>

          <div data-aos="fade-right " className="left  ">
            <div className="">
              <h1 className="heading relative animated">
                <svg
                  id="home-hero-animate"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 500 150"
                  preserveAspectRatio="none"
                >
                  <path d="M9.3,127.3c49.3-3,150.7-7.6,199.7-7.4c121.9,0.4,189.9,0.4,282.3,7.2C380.1,129.6,181.2,130.6,70,139 c82.6-2.9,254.2-1,335.9,1.3c-56,1.4-137.2-0.3-197.1,9"></path>
                </svg>
                {/* <img className='absolute -bottom-6 left-1/2 -translate-x-1/2 w-1/2 scale-y-[0.2] filter-hashpro' src="/global/decoration/underline-stroke.png" alt="" /> */}
                <span id="home-hero-text-anim" className="text-white font-bold">
                  Learn with Purpose,
                  <br />
                  <span className="">
                    <span className="text-hashpro">
                      {" "}
                      grow
                      {/* <span className='scale-[0.8] -mr-4 translate-y-2 md:translate-y-4 md:-mr-8 inline-block  overflow-hidden relative bg-[#131313] border-[#333333] border-solid border-2 text-white lowercase rounded-[3rem] px-4'> <span className='opacity-0'> Grow</span>
                                    <div className='absolute top-1/2 -translate-y-1/2 left-0'>
                                        <div className='move-anim-grow flex gap-x-1'>
                                            <img className='sm:w-8 sm:h-8 w-6 h-6  my-auto' src="/global/icons/star.webp" alt="" /><p className='italic'>grow </p>
                                            <img className='sm:w-8 sm:h-8 w-6 h-6  my-auto' src="/global/icons/star.webp" alt="" /><p className='italic'>grow </p>
                                            <img className='sm:w-8 sm:h-8 w-6 h-6  my-auto' src="/global/icons/star.webp" alt="" /><p className='italic'>grow </p>
                                        </div>
                                    </div>
                                </span> */}
                      &nbsp;with confidence
                    </span>
                  </span>
                </span>
              </h1>
              <p
                style={{ color: "white", fontWeight: "500", lineHeight: "1.5" }}
                className="md:w-[80%] mt-12 md:mt-6 mx-auto  leading-loose subheading text-black font-semibold"
              >
                At Hashpro we believe that you need to choose a career and not
                just a course. Our programs don’t just focus on teaching you
                subject rather help you build yourself.{" "}
              </p>

              <div className="flex flex-col  md:flex-row mx-auto justify center gap-4 w-fit mt-12">
                <a href="#courses-a">
                  <button className="black mx-auto">Explore Programs</button>
                </a>
                <a href="#home-cta">
                  <button className="white mx-auto">Get Counselled</button>
                </a>
              </div>
            </div>
            {/* <div className="subheading">
          <h4>HashPro Academy - Your Journey Starts Here</h4>
      </div> */}
            <div className="banner">
              <LiteYouTubeEmbed
                className=""
                style={{ width: "90%" }}
                id="recjZMPCR8A"
                title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
              />
              {/* <img
                            src="https://hashproacademy.com/digital-marketing-xperential-bootcamp/images/dmx-thumbnail.webp"
                            alt=""
                        /> */}
            </div>

            {/* <div className="cta-2">
          <a href="#courses-a">
              <p className="left">Explore Courses</p>
          </a>
      </div> */}
            {/* <img loading="eager" className="moving-img m-bottom-left" src="./design/bar-graph.svg" /> */}
            {/* <img loading="eager" className="moving-img m-top-left moving" src="./design/1F393_color.png" /> */}
          </div>
          {/* 
  <div data-aos="fade-left" className="right">
      <img loading="eager" className="moving-img m-top-right" src="./design/star.svg" />
      <img loading="eager" className="moving-img m-bottom-right" src="./design/dialogue.svg" />
      <img loading="eager" className="hero-img" src="https://hashproacademy.com/images/hero.svg" />
  </div> */}
          <div className="credibility">
            <div className="first">
              <div className="card">
                <img
                  src="/global/images/hero-cards/google-reviews.png"
                  alt=""
                />
                <h3>
                  Rated <NumberCounterDecimal targetNumber={4.5} />
                  /5
                </h3>
                <p>Google Reviews</p>
              </div>
              <div className="card">
                <img
                  src="/global/images/hero-cards/success-stories.png"
                  alt=""
                />
                <h3>
                  <NumberCounter targetNumber={400} />+
                </h3>
                <p>Success Stories</p>
              </div>
              <div className="card">
                <img src="/global/images/hero-cards/community.png" alt="" />
                <h3>
                  <NumberCounter targetNumber={10} />
                  L+
                </h3>
                <p>Community</p>
              </div>
            </div>
          </div>
        </section>
        <section className="usp-scroller-container">
          <div className="usp-scroller forward">
            <div className="flex">
              <img src="../global/icons/star.webp" alt="" />

              <p className="italic-gradient">Industry-Focused Curriculum</p>
              <img src="../global/icons/star.webp" alt="" />

              <p className="italic-gradient">Industry-Focused Curriculum</p>
              <img src="../global/icons/star.webp" alt="" />

              <p className="italic-gradient">Industry-Focused Curriculum</p>
              <img src="../global/icons/star.webp" alt="" />

              <p className="italic-gradient">Industry-Focused Curriculum</p>
              <img src="../global/icons/star.webp" alt="" />

              <p className="italic-gradient">Industry-Focused Curriculum</p>
              <img src="../global/icons/star.webp" alt="" />

              <p className="italic-gradient">Industry-Focused Curriculum</p>
            </div>
          </div>
          <div className="usp-scroller reverse">
            <div className="flex">
              <img src="../global/icons/star.webp" alt="" />
              <p className="bold">Real-Time Practitioners</p>
              <p className="italic-gradient">Continuous Support</p>
              <img src="../global/icons/star.webp" alt="" />
              <p className="bold">Real-Time Practitioners</p>
              <p className="italic-gradient">Continuous Support</p>
              <img src="../global/icons/star.webp" alt="" />
              <p className="bold">Real-Time Practitioners</p>
              <p className="italic-gradient">Continuous Support</p>
              <img src="../global/icons/star.webp" alt="" />
              <p className="bold">Real-Time Practitioners</p>
              <p className="italic-gradient">Continuous Support</p>
              <img src="../global/icons/star.webp" alt="" />
              <p className="bold">Real-Time Practitioners</p>
              <p className="italic-gradient">Continuous Support</p>
              <img src="../global/icons/star.webp" alt="" />
              <p className="bold">Real-Time Practitioners</p>
              <p className="italic-gradient">Continuous Support</p>
            </div>
          </div>
        </section>
        <section className="course-heading">
          <h1 className="heading">Exclusive Programs</h1>
          <h3 className="subheading">
            Master Your Craft through Industry-Built Curriculum
          </h3>
        </section>
        <section className="-translate-y-64" id="courses-a" />
        <section className="courses-container-2">
          {/* <div style={{ backgroundImage: 'linear-gradient(#00000033,#00000055),url("/global/decoration/black-grain.svg")' }} className="course-card ">
                        <div className="info">
                            <div className="star-redirect-container">
                                <div className="star">
                                    <img src="./global/icons/star.webp" alt="" />
                                    <p>Trendinsgitsg</p>
                                </div>
                                <Link
                                    to="/digital-marketing/">
                                    <div className="redirect">
                                        <span> View Program</span>
                                        <div className="arrow-anim-container">
                                            <div className="slider">
                                                <img src="./global/icons/right-arrow.webp" alt="" />
                                                <img src="./global/icons/right-arrow.webp" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <h2 className='underline decoration-hashpro'> <span className='inline-block relative'></span>Digital Marketing</h2>
                            <p className="para">
                                Master the tools and techniques of Digital Marketing in our 'Digital
                                Marketing' program. From SEO to PPC ads, navigate your way
                                into the digital world. Step up your marketing knowledge with all the
                                ‘essentials’!
                            </p>
                            <div className="bullets">
                                <div className="bullet">
                                    <img src="../global/icons/right-arrow.webp" alt="" />
                                    <p>
                                        Dedicated Faculty with continuous support Post-Course Completion
                                    </p>
                                </div>
                                <div className="bullet">
                                    <img src="../global/icons/right-arrow.webp" alt="" />
                                    <p>60 Sessions Classroom Training</p>
                                </div>
                                <div className="bullet">
                                    <img src="../global/icons/right-arrow.webp" alt="" />
                                    <p>Hands-on Learning Experience</p>
                                </div>
                            </div>
                        </div>
                        <div className="media w-fit h-fit  overflow-hidden">
                            <div className='w-fit h-fit pr-[3px]'>
                                <img className='border-2 border-black  border-solid rounded-xl shadow-[3px_3px_white] object-cover object-center aspect-video' src="/global/home/images/courses/DME.webp" alt="" />
                            </div>
                        </div>
                    </div> */}

          <div
            style={{
              backgroundImage:
                "linear-gradient(#131313aa,#131313aa),url(/global/decoration/black-grain.svg)",
              backgroundSize: "100%",
            }}
            className="course-card "
          >
            <div className="info">
              <div className="star-redirect-container">
                <div className="star">
                  <img src="./global/icons/star.webp" alt="" />
                  <p>ON DEMAND</p>
                </div>
                <a href="/art-of-influence">
                  <div className="redirect">
                    <span> View Program</span>
                    <div className="arrow-anim-container">
                      <div className="slider">
                        <img src="./global/icons/right-arrow.webp" alt="" />
                        <img src="./global/icons/right-arrow.webp" alt="" />
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <h2 className="underline decoration-hashpro">Art of Influence</h2>
              <p className="para">
                Experience the 'Art of Influence' in our transformative program
                designed for career excellence. Tailored for optimal learning,
                hands-on with intimate groups, and interactive methodologies.
                Elevating your skills for a confident and successful career with
                a practical approach.
              </p>
              <div className="bullets">
                <div className="bullet">
                  <img src="../global/icons/right-arrow.webp" alt="" />
                  <p>
                    Guest Lectures from different Industries for diverse
                    knowledge
                  </p>
                </div>
                <div className="bullet">
                  <img src="../global/icons/right-arrow.webp" alt="" />
                  <p>
                    A hands-on approach that encourages active participation
                  </p>
                </div>
                <div className="bullet">
                  <img src="../global/icons/right-arrow.webp" alt="" />
                  <p>Curated for Personal &amp; Professional Growth</p>
                </div>
              </div>
              <p className="text-md mt-5">
                {" "}
                <span className="underline font-semibold">Duration</span> : 1.5
                Months
              </p>
              <p className="text-md mt-2">
                {" "}
                <span className="underline font-semibold">Pricing</span> :
                ₹44,000 (Including GST)
              </p>
            </div>
            <div className="media w-fit h-fit  overflow-hidden">
              <div className="w-fit h-fit pr-[3px]">
                <img
                  className="border-2 border-white  border-solid rounded-xl shadow-[3px_3px_#f8d00d] object-cover object-center aspect-video"
                  src="/global/home/images/courses/AOI.webp"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundImage:
                'linear-gradient(#ffffff33,#ffffff55),url("/global/decoration/white-grain.svg")',
              backgroundSize: "80rem",
            }}
            className="course-card "
          >
            <div className="info">
              <div className="star-redirect-container">
                <div className="star">
                  <img src="./global/icons/star.webp" alt="" />
                  <p>New</p>
                </div>
                <a href="/digital-marketing-xperential-bootcamp/">
                  <div className="redirect">
                    <span> View Bootcamp</span>
                    <div className="arrow-anim-container">
                      <div className="slider">
                        <img src="./global/icons/right-arrow.webp" alt="" />
                        <img src="./global/icons/right-arrow.webp" alt="" />
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <h2 className="underline decoration-hashpro">DMX BootCamp </h2>
              <p className="para">
                Immerse yourself in DMX - Digital Marketing Experiential
                Bootcamp, a program that delves deeper into the intricacies of
                the digital marketing landscape with a comprehensive exploration
                of every facet to elevate your skills in the Digital World!
              </p>
              <div className="bullets">
                <div className="bullet">
                  <img src="../global/icons/right-arrow.webp" alt="" />
                  <p>Diverse Industry Insights through Guest Lectures</p>
                </div>
                <div className="bullet">
                  <img src="../global/icons/right-arrow.webp" alt="" />
                  <p>
                    Active Participation in a Practical Approach like Ad
                    Decoding
                  </p>
                </div>
                <div className="bullet">
                  <img src="../global/icons/right-arrow.webp" alt="" />
                  <p>
                    Comprehensive Curriculum for Specialized Skillsets &amp;
                    Projects
                  </p>
                </div>
              </div>
              <p className="text-md mt-5">
                {" "}
                <span className="underline font-semibold">Duration</span> : 4
                Months
              </p>
              <p className="text-md mt-2">
                {" "}
                <span className="underline font-semibold">Pricing</span> :
                ₹69,000 (Including GST)
              </p>
            </div>
            <div className="media w-fit h-fit  overflow-hidden">
              <div className="w-fit h-fit pr-[3px]">
                <img
                  className="border-2 border-black  border-solid rounded-xl shadow-[3px_3px_white] object-cover object-center aspect-video"
                  src="/global/home/images/courses/students.webp"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section id="whyhashpro-a" />
        <section className="usp-glow-container  bg-black-grain">
          {/* <div className="absolute bg-hashpro top-0 bottom-0 w-12 right-0"></div> */}
          <div className="heading-container">
            <h1 className="heading">
              Elevating{" "}
              <span
                style={{
                  display: "inline-block",
                  position: "relative",
                  width: "fit-content",
                }}
              >
                Excellence
                {/* <img src="/icons/shining-star.png" alt="" /> */}
                <p className="text-4xl absolute -top-[40%] -right-[20%]">✨</p>
              </span>
            </h1>
            <h1></h1>
            <h3 className="subheading">
              Writing a journey for you where timeless values lay the foundation
              for success.
            </h3>
          </div>
          <div className="usp-container rounded-2xl">
            <div className="text-box">
              <p>Diverse skill set</p>
            </div>
            <div className="text-box">
              <p> Abundant opportunities</p>
            </div>
            <div className="text-box">
              <p>Gateway to startups</p>
            </div>
            <div className="text-box">
              <p>200+ Students</p>
            </div>
            <div className="text-box">
              <p>25+ Batches</p>
            </div>
            <div className="text-box">
              <p>50K+ Family</p>
            </div>
            <div className="text-box">
              <p>Industry-Focused curriculum</p>
            </div>
            <div className="text-box">
              <p>Collaborative Learning</p>
            </div>
            <div className="text-box">
              <p>Real-time practitioners</p>
            </div>
            <div className="text-box">
              <p>Continuous support</p>
            </div>
          </div>
        </section>
        <section className="industry-container">
          <div className="industry-ready">
            <div className="text" data-aos="zoom-in">
              <h1 className="heading">
                Education that Empowers you to Thrive in the
                <br />{" "}
                <span
                  style={{
                    display: "block",
                    width: "fit-content",
                    textAlign: "center",
                    margin: "0 auto",
                    backgroundColor: "#131313",
                    padding: "5px 22px",
                    borderRadius: 5,
                  }}
                  className="yellow"
                >
                  Digital Age
                </span>
              </h1>
              <p className="subheading">
                It is where Passion Meets Purpose and Careers Begin to Soar!
                <br /> Here Knowledge Blooms, Careers Flourish, and Success
                Finds Its Home!
              </p>
            </div>
            <div data-aos="zoom-in" className="stats" data-aos-offset={0}>
              <div className="stats-card-container">
                <div className="stats-card">
                  <h1>
                    <p>
                      <span id="counter-1" />
                      <span className="symbol">+</span>
                    </p>
                  </h1>
                  <div className="underline-container" />
                  <p className="bottom">Students</p>
                </div>
              </div>
              <div className="stats-card-container">
                <div className="stats-card">
                  <h1>
                    <p>
                      <span id="counter-2" />
                      <span className="symbol">+</span>
                    </p>
                  </h1>
                  <div className="underline-container" />
                  <p className="bottom">Batches </p>
                </div>
              </div>
              <div className="stats-card-container">
                <div className="stats-card">
                  <h1>
                    <p>
                      <span className="symbol">#</span>
                      <span id="counter-3" />
                    </p>
                  </h1>
                  <div className="underline-container" />
                  <p className="bottom">in Hyderabad </p>
                </div>
              </div>
              <div className="stats-card-container">
                <div className="stats-card">
                  <h1>
                    <p>
                      <span id="counter-4" />
                      <span className="symbol">
                        <span className="letter-symbol">K</span>+
                      </span>
                    </p>
                  </h1>
                  <div className="underline-container" />
                  <p className="bottom">Family</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="courses-a" />
        <section className="hidden">
          <h1 className="heading">Exclusive Programs</h1>
          <h3 className="text-center subheading">
            Master Your Craft through Industry-Built Curriculum
          </h3>
          <Splide
            className="pb-16 mt-16"
            options={{
              // perPage: 'auto',
              focus: "center",
              gap: "10px",
              pagination: false,
              autoplay: true,
              interval: 3500,
              rewind: true,
              isNavigation: true,
              breakpoints: {
                600: {
                  perPage: 1,
                },
                1000: {
                  perPage: 2,
                },
                1300: {
                  perPage: 2,
                },
                1500: {
                  perPage: 3,
                },
              },
            }}
          >
            <SplideSlide>
              <div className="p-6 bg-[#202020] border-white border-solid  rounded-2xl aspect-square shadow-[3px_3px_white]">
                <div className="info">
                  <div className="flex justify-between">
                    <div className="bg-hashpro flex whitespace-nowrap py-1 px-2 gap-2 rounded w-fit h-fit my-auto">
                      <img
                        className="invert w-4 h-4 my-auto"
                        src="./global/icons/star.webp"
                        alt=""
                      />
                      <p className="my-auto text-black text-sm font-bold">
                        ON DEMAND
                      </p>
                    </div>
                    <a href="/art-of-influence/">
                      <span className="overflow-hidden relative inline-block pb-4">
                        View Program
                        <div className="move-right-arrows-course absolute -bottom-0.5 left-0 right-0 flex whitespace-nowrap">
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                        </div>
                      </span>
                    </a>
                  </div>
                  <h2 className="mt-6 text-3xl underline decoration-hashpro">
                    Digital Marketing
                  </h2>
                  <p className="mt-3 text-sm">
                    Master the tools and techniques of Digital Marketing in our
                    'Digital Marketing' program. From SEO to PPC ads, navigate
                    your way into the digital world. Step up your marketing
                    knowledge with all the ‘essentials’!
                  </p>

                  <p className="text-sm mt-5">
                    {" "}
                    <span className="underline font-semibold">Duration</span> :
                    3 Months
                  </p>
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="aspect-square w-fit h-fit overflow-hidden">
                <div className="w-fit h-fit pr-[3px]">
                  <img
                    className=" border-white  border-solid rounded-xl shadow-[3px_3px_#f8d00d] object-cover object-center aspect-square"
                    src="/global/home/images/courses/DME.webp"
                    alt=""
                  />
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div
                style={{
                  backgroundImage: "url(/global/decoration/hashpro-grain.svg)",
                }}
                className="aspect-square  text-black shadow-[3px_3px_white] rounded-2xl relative"
              >
                <div className="flex flex-col gap-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full p-4">
                  <div className="flex gap-2">
                    <img
                      className="w-4 h-4 my-auto "
                      src="../global/icons/right-arrow.webp"
                      alt=""
                    />
                    <p className="text-sm font-semibold">
                      Dedicated Faculty with continuous support Post-Course
                      Completion
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <img
                      className="w-4 h-4 my-auto "
                      src="../global/icons/right-arrow.webp"
                      alt=""
                    />
                    <p className="text-sm font-semibold">
                      60 Sessions Classroom Training
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <img
                      className="w-4 h-4 my-auto "
                      src="../global/icons/right-arrow.webp"
                      alt=""
                    />
                    <p className="text-sm font-semibold">
                      Hands-on Learning Experience
                    </p>
                  </div>
                </div>
              </div>
            </SplideSlide>
          </Splide>
          <Splide
            className="pb-16 mt-16"
            options={{
              // perPage: 'auto',
              focus: "center",
              gap: "10px",
              pagination: false,
              autoplay: true,
              interval: 3500,
              rewind: true,
              isNavigation: true,
              breakpoints: {
                600: {
                  perPage: 1,
                },
                1000: {
                  perPage: 2,
                },
                1300: {
                  perPage: 2,
                },
                1500: {
                  perPage: 3,
                },
              },
            }}
          >
            <SplideSlide>
              <div className="aspect-square w-fit h-fit overflow-hidden">
                <div className="w-fit h-fit pr-[3px]">
                  <img
                    className=" border-white  border-solid rounded-xl shadow-[3px_3px_#f8d00d] object-cover object-center aspect-square"
                    src="/global/home/images/courses/DMX.webp"
                    alt=""
                  />
                </div>
              </div>
            </SplideSlide>

            <SplideSlide>
              <div className="p-6 bg-[#202020] border-white border-solid  rounded-2xl aspect-square shadow-[3px_3px_white]">
                <div className="info">
                  <div className="flex justify-between">
                    <div className="bg-hashpro flex whitespace-nowrap py-1 px-2 gap-2 rounded w-fit h-fit my-auto">
                      <img
                        className="invert w-4 h-4 my-auto"
                        src="./global/icons/star.webp"
                        alt=""
                      />
                      <p className="my-auto text-black text-sm font-bold">
                        ON DEMAND
                      </p>
                    </div>
                    <a href="/art-of-influence/">
                      <span className="overflow-hidden relative inline-block pb-4">
                        View Program
                        <div className="move-right-arrows-course absolute -bottom-0.5 left-0 right-0 flex whitespace-nowrap">
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                        </div>
                      </span>
                    </a>
                  </div>
                  <h2 className="mt-6 text-3xl underline decoration-hashpro">
                    DMX BootCamp
                  </h2>
                  <p className="mt-3 text-sm">
                    Immerse yourself in DMX - Digital Marketing Experiential
                    Bootcamp, a program that delves deeper into the intricacies
                    of the digital marketing landscape with a comprehensive
                    exploration of every facet to elevate your skills in the
                    Digital World!
                  </p>

                  <p className="text-sm mt-5">
                    {" "}
                    <span className="underline font-semibold">Duration</span> :
                    3 Months
                  </p>
                </div>
              </div>
            </SplideSlide>

            <SplideSlide>
              <div
                style={{
                  backgroundImage: "url(/global/decoration/hashpro-grain.svg)",
                }}
                className="aspect-square text-black shadow-[3px_3px_white] rounded-2xl relative"
              >
                <div className="flex flex-col gap-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full p-4">
                  <div className="flex gap-2">
                    <img
                      className="w-4 h-4 my-auto "
                      src="../global/icons/right-arrow.webp"
                      alt=""
                    />
                    <p className="text-sm font-semibold">
                      Diverse Industry Insights through Guest Lectures
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <img
                      className="w-4 h-4 my-auto "
                      src="../global/icons/right-arrow.webp"
                      alt=""
                    />
                    <p className="text-sm font-semibold">
                      Active Participation in a Practical Approach like Ad
                      Decoding
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <img
                      className="w-4 h-4 my-auto "
                      src="../global/icons/right-arrow.webp"
                      alt=""
                    />
                    <p className="text-sm font-semibold">
                      Comprehensive Curriculum for Specialized Skillsets &
                      Projects
                    </p>
                  </div>
                </div>
              </div>
            </SplideSlide>
          </Splide>
          <Splide
            className="pb-16 mt-16"
            options={{
              // perPage: 'auto',
              focus: "center",
              gap: "10px",
              pagination: false,
              autoplay: true,
              interval: 3500,
              rewind: true,
              isNavigation: true,

              // autoWidth: true,
              breakpoints: {
                600: {
                  perPage: 1,
                },
                1000: {
                  perPage: 2,
                },
                1300: {
                  perPage: 2,
                },
                1500: {
                  perPage: 3,
                },
              },
            }}
          >
            <SplideSlide>
              <div className="p-6 bg-[#202020] border-white border-solid aspect-square rounded-2xl shadow-[3px_3px_white]">
                <div className="info">
                  <div className="flex justify-between">
                    <div className="bg-hashpro flex whitespace-nowrap py-1 px-2 gap-2 rounded w-fit h-fit my-auto">
                      <img
                        className="invert w-4 h-4 my-auto"
                        src="./global/icons/star.webp"
                        alt=""
                      />
                      <p className="my-auto text-black text-sm font-bold">
                        ON DEMAND
                      </p>
                    </div>
                    <a href="/art-of-influence/">
                      <span className="overflow-hidden relative inline-block pb-4">
                        View Program
                        <div className="move-right-arrows-course absolute -bottom-0.5 left-0 right-0 flex whitespace-nowrap">
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                          <img
                            className="invert w-4"
                            src="./global/icons/right-arrow.webp"
                            alt=""
                          />
                        </div>
                      </span>
                    </a>
                  </div>
                  <h2 className="mt-6 text-3xl underline decoration-hashpro">
                    Art of Influence
                  </h2>
                  <p className="mt-3 text-sm">
                    Experience the 'Art of Influence' in our transformative
                    program designed for career excellence. Tailored for optimal
                    learning, hands-on with intimate groups, and interactive
                    methodologies. Elevating your skills for a confident and
                    successful career with a practical approach.
                  </p>
                  <p className="text-sm mt-5">
                    {" "}
                    <span className="underline font-semibold">Duration</span> :
                    3 Months
                  </p>
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="aspect-square bg-hashpro text-black shadow-[3px_3px_white] rounded-2xl relative">
                <div className="flex flex-col gap-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full p-4">
                  <div className="flex gap-2">
                    <img
                      className="w-4 h-4 my-auto "
                      src="../global/icons/right-arrow.webp"
                      alt=""
                    />
                    <p className="text-sm font-semibold">
                      Guest Lectures from different Industries for diverse
                      knowledge
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <img
                      className="w-4 h-4 my-auto "
                      src="../global/icons/right-arrow.webp"
                      alt=""
                    />
                    <p className="text-sm font-semibold">
                      A hands-on approach that encourages active participation
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <img
                      className="w-4 h-4 my-auto "
                      src="../global/icons/right-arrow.webp"
                      alt=""
                    />
                    <p className="text-sm font-semibold">
                      Curated for Personal &amp; Professional Growth
                    </p>
                  </div>
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="aspect-square w-fit h-fit overflow-hidden">
                <div className="w-fit h-fit pr-[3px]">
                  <img
                    className=" border-white  border-solid rounded-xl shadow-[3px_3px_#f8d00d] object-cover object-center aspect-square"
                    src="/global/home/images/courses/Aoi-classroom.jpg"
                    alt=""
                  />
                </div>
              </div>
            </SplideSlide>
          </Splide>
        </section>

        <section></section>
        <section id="courses" className="relative courses">
          <img
            className="absolute -bottom-[40%] -right-[50%] md:-bottom-[40%] md:rotate-[40deg] md:-right-[20%] opacity-25"
            src="/global/decoration/polygon-hashpro.png"
            alt=""
          />
          <div class="grid md:grid-cols-[40%_60%] gap-10">
            <div className="w-fit h-fit m-auto">
              <h1 className="heading">
                Learn From{" "}
                <span
                  className="bg-[#ffd60f75] rounded border-solid border border-[#000000aa] px-3"
                  style={{ display: "inline-block", position: "relative" }}
                >
                  {" "}
                  Unconventional <img src="/icons/line-crown.png" alt="" />
                </span>
              </h1>
              <h3 className="subheading">
                A dedicated group shaping excellence with precision and
                creativity
              </h3>
            </div>
            <div className="overflow-hidden ">
              <Splide
                options={{
                  perPage: "auto",
                  focus: "left",
                  gap: "100px",
                  pagination: false,
                  autoplay: true,
                  interval: 3000,
                  rewind: true,
                  isNavigation: true,
                  breakpoints: {
                    800: {
                      perPage: 1,
                    },
                    1300: {
                      perPage: 2,
                    },
                    1500: {
                      perPage: 3,
                    },
                  },
                }}
              >
                <SplideSlide>
                  <div className="faculty">
                    <img
                      className="faculty-img"
                      src="./global/images/people/Vamshi-kurapati-Co-founder-of-hashproacademy.webp"
                    />
                    <div className="text">
                      <div className="profile">
                        <p className="name">Vamshi Kurapati</p>
                        <p className="desc">Co-Founder </p>
                      </div>
                      <div className="social-media">
                        <a
                          target="_blank"
                          href="https://www.youtube.com/@rawtalkswithvk"
                        >
                          <img
                            loading="lazy"
                            src="./global/icons/youtube.webp"
                          />
                        </a>
                        <a
                          target="_blank"
                          href="https://www.instagram.com/thevamshikurapati/"
                        >
                          <img
                            loading="lazy"
                            src="./global/icons/instagram.webp"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div className="faculty">
                    <img
                      className="faculty-img"
                      src="./global/images/people/Pranavi-guptha-Co-founder-of-hashproacademy.webp"
                    />
                    <div className="text">
                      <div className="profile">
                        <p className="name">Pranavi Gupta</p>
                        <p className="desc">Co-Founder</p>
                      </div>
                      <div className="social-media">
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/in/pranavigupta/"
                        >
                          <img
                            loading="lazy"
                            src="./global/icons/linkedin.webp"
                          />
                        </a>
                        <a
                          target="_blank"
                          href="https://www.instagram.com/pranavigupta/"
                        >
                          <img
                            loading="lazy"
                            src="./global/icons/instagram.webp"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </SplideSlide>

                <SplideSlide>
                  <div className="faculty">
                    <img
                      className="faculty-img"
                      src="./global/images/people/Naveen-Co-founder-of-hashproacademy.webp"
                    />
                    <div className="text">
                      <div className="profile">
                        <p className="name">Naveen Sai Kuriti</p>
                        <p className="desc">Advisor</p>
                      </div>
                      <div className="social-media">
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/in/naveen-sai-kuriti-076904151"
                        >
                          <img
                            loading="lazy"
                            src="/global/icons/linkedin.webp"
                          />
                        </a>
                        <a
                          target="_blank"
                          href="https://www.instagram.com/naveensaikuriti/"
                        >
                          <img
                            loading="lazy"
                            src="/global/icons/instagram.webp"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </SplideSlide>

                <SplideSlide>
                  <div className="faculty">
                    <img
                      className="faculty-img"
                      src="./global/images/people/Fahad-hasan-faculty-hashproacademy.webp"
                    />
                    <div className="text">
                      <div className="profile">
                        <p className="name">Fahad Hasan</p>
                        <p className="desc">Digital Marketing Faculty</p>
                      </div>
                      <div className="social-media">
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/in/fahadhasan951/"
                        >
                          <img
                            loading="lazy"
                            src="./global/icons/linkedin.webp"
                          />
                        </a>
                        <a
                          target="_blank"
                          href="https://www.instagram.com/sarcastic.teacher/"
                        >
                          <img
                            loading="lazy"
                            src="./global/icons/instagram.webp"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </SplideSlide>

                <SplideSlide>
                  <div className="faculty">
                    <img
                      className="faculty-img"
                      src="./global/images/people/Soumya-trainer-hashproacademy.webp"
                    />
                    <div className="text">
                      <div className="profile">
                        <p className="name">Soumya Vijay Kandi</p>
                        <p className="desc">Certified Corporate Trainer</p>
                      </div>

                      <div className="social-media">
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/in/soumyavijay-kandi-74a0a6118"
                        >
                          <img
                            loading="lazy"
                            src="./global/icons/linkedin.webp"
                          />
                        </a>
                        {/* <a target="_blank" target="_blank" href="https://www.google.com"><img loading="lazy"  src="./global/icons/instagram.webp" /></a> */}
                      </div>
                    </div>
                  </div>
                </SplideSlide>

                <SplideSlide>
                  <div className="faculty">
                    <img
                      className="faculty-img"
                      src="./global/images/people/Ananth-faculty-hashproacademy.webp"
                    />
                    <div className="text">
                      <div className="profile">
                        <p className="name">Ananth Ram Gadepalli</p>
                        <p className="desc">
                          Head of Sales, Ex-Bjyus &amp; White hat
                        </p>
                      </div>
                      <div className="social-media">
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/in/pranavigupta/"
                        >
                          <img
                            loading="lazy"
                            src="./global/icons/linkedin.webp"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
          </div>
        </section>
        <section className="why-hashpro">
          <h1 className="heading">Why HashPro?</h1>
          <p className="subheading">
            A place where a new beginning of a career starts.
          </p>
          <div className="grid">
            <div className="banner">
              <img src="/global/home/images/Hashpro-students.webp" alt="" />
            </div>
            <div className="card">
              <img src="/global/home/images/why/co.png" alt="" />
              <h3>Career Opportunities</h3>
              <p>Discover career opportunities within our network.</p>
            </div>
            <div className="card">
              <img src="/global/home/images/why/pa.png" alt="" />
              <h3>Practical Approach</h3>
              <p>
                Learn by doing by solving real-life problems from real
                companies.
              </p>
            </div>
            <div className="card">
              <img src="/global/home/images/why/cb.png" alt="" />
              <h3>Community at the Center</h3>
              <p>
                Join the curated community of other learners, alumni, and
                mentors.
              </p>
            </div>
          </div>
        </section>
        <section
          style={{
            backgroundImage:
              "linear-gradient(#000000dd,#000000dd), url(/global/home/images/bg-grid.png)",
            backgroundSize: "54px",
          }}
          className="bg-[#505050] py-8 "
        >
          <div className="gap-y-16 grid md:grid-cols-[70%_30%] py-16 md:py-24 px-12 bg-[#131313] border-2 border-solid border-[#ffffff0d]">
            <div>
              <h3 className="text-4xl font-bold">
                {" "}
                <span className="text-hashpro">Master Your Future</span> with us
              </h3>
              <h4 className="font-medium mt-4">
                Step into the world of Digital Marketing to turbocharge your
                career, and equip yourself with cutting-edge skills with Art of
                Influence.
              </h4>
            </div>
            <div className="w-fit h-fit m-auto">
              <a href="#courses-a">
                <button className="yellow relative">
                  <img
                    className="w-8 absolute top-1/2 -left-[30%] -translate-y-1/2 rotate-180"
                    src="/global/icons/be-right.png"
                    alt=""
                  />
                  Explore Programs
                  <img
                    className="w-8 absolute top-1/2 -right-[30%] -translate-y-1/2"
                    src="/global/icons/be-right.png"
                    alt=""
                  />
                </button>
              </a>
            </div>
          </div>
        </section>
        {/* <section
          style={{
            backgroundImage:
              "linear-gradient(3deg, rgb(12 12 12 / 99%), #0d0d0dde), url(/global/home/images/bg-grid.png)",
            backgroundSize: "54px",
          }}
          className="py-20"
        >
          <div className="border border-solid  border-[#656565] grid md:grid-cols-[40%_60%] bg-[#1a1a1a] shadow-[2px_2px_#f8d00d] rounded-xl overflow-hidden ">
            <img
              className="w-full bg-[#1a1a1a] m-auto"
              src="/global/people/vamshi-kurapati-Co-founder-of-hashproacademy.webp"
              alt=""
            />
            <div className="bg-[#131313] p-8 md:p-12">
              <h2 className="text-2xl italic">
                Headed by{" "}
                <span className="text-hashpro "> Vamshi Kurapati</span>
              </h2>
              <p className="mt-2 text-sm leading-loose">
                Vamshi Kurapati is a multi-faceted entrepreneur and influencer
                who's passionate about empowering Telugu community. After
                founding a successful digital marketing agency, Swio Corporate,
                Vamshi co-founded HashPro, to equip individuals with the skills
                and tools to thrive in their careers.
              </p>
              <div className="mt-8 flex flex-col gap-3">
                <a
                  href="https://www.youtube.com/@rawtalkswithvk"
                  target="_blank"
                >
                  <div className="flex gap-2">
                    <img
                      className="w-[25px] h-[17px] shrink-0 my-auto"
                      src="/global/icons/youtube-red.webp"
                      alt=""
                    />
                    <p className="text-sm font-light  text-xs">
                      &nbsp;Podcast Host: Raw Talks with VK (1.19M+ subscribers)
                    </p>
                  </div>
                </a>
                <a
                  href="https://www.instagram.com/thevamshikurapati/"
                  target="_blank"
                >
                  <div className="flex gap-2">
                    <img
                      className="w-[25px] h-[25px] shrink-0 my-auto"
                      src="/global/icons/instagram-color.webp"
                      alt=""
                    />
                    <p className="text-sm my-auto  font-light  text-xs">
                      &nbsp;Telugu Entrepreneur Influencer (617K+ followers)
                    </p>
                  </div>
                </a>
                <p className="font-light text-sm mt-2 italic text-xs ">
                  Building an Intellectual Telugu Community
                </p>
              </div>
            </div>
          </div>
        </section> */}

        <section className="testimonial-wave" />
        <section className="testimonial relative">
          <div className="w-[20rem] h-[20rem] bg-transparent absolute -top-[5%] z-[-1] -left-[50%] md:-left-[10%] rounded-[50%] border-[1rem] border-solid border-[#1e1e1e]"></div>
          <div className="w-[15rem] h-[15rem] md:w-[20rem] md:h-[20rem] bg-[#131313] absolute -bottom-[5%] z-[-1] -right-[40%]  md:-right-[10%] rounded-[50%]"></div>
          <h1 className="heading">
            Hear what our <span className="yellow">Students</span>
            <span>
              {" "}
              have to{" "}
              <span style={{ position: "relative", display: "inline-block" }}>
                say.
                <img
                  loading="lazy"
                  className="heading-img"
                  src="./icons/chat.png"
                />
              </span>
            </span>
            <span> </span>
          </h1>
          <h3 className="subheading">We have happy students</h3>
          <div className="testimonial-cards">
            <div className="testimonial-card-container">
              <div className="testimonial-card tc-1">
                <p className="para">
                  Best graphic design institute in hyd They don't just teach
                  photoshop tool They make us understand what designing is,
                  visualisation etc Thank you Seema Mam for your wonderful
                  training
                </p>
                <div className="testimonial-profile">
                  <div className="testimonial-profile-text">
                    <p style={{ color: "var(--hashpro)" }} className="name">
                      Ea incididunt{" "}
                    </p>
                    <p className="designation">Pariatur anim, laborum </p>
                  </div>
                </div>
                <img
                  loading="lazy"
                  className="quote"
                  src="./global/icons/quotation.webp"
                />
              </div>
            </div>
            <div className="testimonial-card-container">
              <div className="testimonial-card tc-2">
                <p className="para">
                  This ain't yet another coaching institute. What sets hashpro
                  academy apart from other institutes is their Mentorship and
                  they do the teaching from heart. Great workshops and
                  affordable Courses 👍🏼. The faculty and mentors are experts in
                  their fields. Your goto place for any digital marketing needs.
                </p>
                <div className="testimonial-profile">
                  <div className="testimonial-profile-text">
                    <p style={{ color: "var(--hashpro)" }} className="name">
                      Ea incididunt{" "}
                    </p>
                    <p className="designation">Pariatur anim, laborum </p>
                  </div>
                </div>
                <img
                  loading="lazy"
                  className="quote"
                  src="./global/icons/quotation.webp"
                />
              </div>
            </div>
            <div className="testimonial-card-container">
              <div className="testimonial-card tc-3">
                <p className="para">
                  Would recommend who are all willing to learn Digital marketing
                  in a professional way. I went for an enquiry there, the
                  interior was awstruck. One of the most important aspects while
                  learning is good friendly environment and interior designs.
                  Those I've found that in this hashpro.. very nice guys. Keep
                  going.
                </p>
                <div className="testimonial-profile">
                  <div className="testimonial-profile-text">
                    <p style={{ color: "var(--hashpro)" }} className="name">
                      Ea incididunt{" "}
                    </p>
                    <p className="designation">Pariatur anim, laborum </p>
                  </div>
                </div>
                <img
                  loading="lazy"
                  className="quote"
                  src="./global/icons/quotation.webp"
                />
              </div>
            </div>
            <div className="testimonial-card-container">
              <div className="testimonial-card tc-4">
                <p className="para">
                  I have attended a workshop at Hashpro academy and got to learn
                  many thing's. the content and lecture was very detailed and
                  useful
                </p>
                <div className="testimonial-profile">
                  <div className="testimonial-profile-text">
                    <p style={{ color: "var(--hashpro)" }} className="name">
                      Ea incididunt{" "}
                    </p>
                    <p className="designation">Pariatur anim, laborum </p>
                  </div>
                </div>
                <img
                  loading="lazy"
                  className="quote"
                  src="./global/icons/quotation.webp"
                />
              </div>
            </div>
            <div className="testimonial-card-container">
              <div className="testimonial-card tc-5">
                <p className="para">
                  Great teaching at nominal pricing. Trainer Fahad hasan is very
                  good.
                </p>
                <div className="testimonial-profile">
                  <div className="testimonial-profile-text">
                    <p style={{ color: "var(--hashpro)" }} className="name">
                      Ea incididunt{" "}
                    </p>
                    <p className="designation">Pariatur anim, laborum </p>
                  </div>
                </div>
                <img
                  loading="lazy"
                  className="quote"
                  src="./global/icons/quotation.webp"
                />
              </div>
            </div>
            <div className="testimonial-card-container">
              <div className="testimonial-card tc-1">
                <p className="para">
                  Best graphic design institute in hyd They don't just teach
                  photoshop tool They make us understand what designing is,
                  visualisation etc Thank you Seema Mam for your wonderful
                  training
                </p>
                <div className="testimonial-profile">
                  <div className="testimonial-profile-text">
                    <p style={{ color: "var(--hashpro)" }} className="name">
                      Ea incididunt{" "}
                    </p>
                    <p className="designation">Pariatur anim, laborum </p>
                  </div>
                </div>
                <img
                  loading="lazy"
                  className="quote"
                  src="./global/icons/quotation.webp"
                />
              </div>
            </div>
            <div className="testimonial-card-container">
              <div className="testimonial-card tc-2">
                <p className="para">
                  This ain't yet another coaching institute. What sets hashpro
                  academy apart from other institutes is their Mentorship and
                  they do the teaching from heart. Great workshops and
                  affordable Courses 👍🏼. The faculty and mentors are experts in
                  their fields. Your goto place for any digital marketing needs.
                </p>
                <div className="testimonial-profile">
                  <div className="testimonial-profile-text">
                    <p style={{ color: "var(--hashpro)" }} className="name">
                      Ea incididunt{" "}
                    </p>
                    <p className="designation">Pariatur anim, laborum </p>
                  </div>
                </div>
                <img
                  loading="lazy"
                  className="quote"
                  src="./global/icons/quotation.webp"
                />
              </div>
            </div>
            <div className="testimonial-card-container">
              <div className="testimonial-card tc-3">
                <p className="para">
                  Would recommend who are all willing to learn Digital marketing
                  in a professional way. I went for an enquiry there, the
                  interior was awstruck. One of the most important aspects while
                  learning is good friendly environment and interior designs.
                  Those I've found that in this hashpro.. very nice guys. Keep
                  going.
                </p>
                <div className="testimonial-profile">
                  <div className="testimonial-profile-text">
                    <p style={{ color: "var(--hashpro)" }} className="name">
                      Ea incididunt{" "}
                    </p>
                    <p className="designation">Pariatur anim, laborum </p>
                  </div>
                </div>
                <img
                  loading="lazy"
                  className="quote"
                  src="./global/icons/quotation.webp"
                />
              </div>
            </div>
            <div className="testimonial-card-container">
              <div className="testimonial-card tc-4">
                <p className="para">
                  I have attended a workshop at Hashpro Academy and got to learn
                  many thing's. the content and lecture was very detailed and
                  useful
                </p>
                <div className="testimonial-profile">
                  <div className="testimonial-profile-text">
                    <p style={{ color: "var(--hashpro)" }} className="name">
                      Ea incididunt{" "}
                    </p>
                    <p className="designation">Pariatur anim, laborum </p>
                  </div>
                </div>
                <img
                  loading="lazy"
                  className="quote"
                  src="./global/icons/quotation.webp"
                />
              </div>
            </div>
            <div className="testimonial-card-container">
              <div className="testimonial-card tc-5">
                <p className="para">
                  Great teaching at nominal pricing. Trainer Fahad hasan is very
                  good.
                </p>
                <div className="testimonial-profile">
                  <div className="testimonial-profile-text">
                    <p style={{ color: "var(--hashpro)" }} className="name">
                      Ea incididunt{" "}
                    </p>
                    <p className="designation">Pariatur anim, laborum </p>
                  </div>
                </div>
                <img
                  loading="lazy"
                  className="quote"
                  src="./global/icons/quotation.webp"
                />
              </div>
            </div>
          </div>
          <div className="testimonial-cards reverse-animation">
            <div className="testimonial-card-container">
              <div className="testimonial-card tc-1">
                <p className="para">
                  Best graphic design institute in hyd They don't just teach
                  photoshop tool They make us understand what designing is,
                  visualisation etc Thank you Seema Mam for your wonderful
                  training
                </p>
                <div className="testimonial-profile">
                  <div className="testimonial-profile-text">
                    <p style={{ color: "var(--hashpro)" }} className="name">
                      Ea incididunt{" "}
                    </p>
                    <p className="designation">Pariatur anim, laborum </p>
                  </div>
                </div>
                <img
                  loading="lazy"
                  className="quote"
                  src="./global/icons/quotation.webp"
                />
              </div>
            </div>
            <div className="testimonial-card-container">
              <div className="testimonial-card tc-2">
                <p className="para">
                  This ain't yet another coaching institute. What sets hashpro
                  academy apart from other institutes is their Mentorship and
                  they do the teaching from heart. Great workshops and
                  affordable Courses 👍🏼. The faculty and mentors are experts in
                  their fields. Your goto place for any digital marketing needs.
                </p>
                <div className="testimonial-profile">
                  <div className="testimonial-profile-text">
                    <p style={{ color: "var(--hashpro)" }} className="name">
                      Ea incididunt{" "}
                    </p>
                    <p className="designation">Pariatur anim, laborum </p>
                  </div>
                </div>
                <img
                  loading="lazy"
                  className="quote"
                  src="./global/icons/quotation.webp"
                />
              </div>
            </div>
            <div className="testimonial-card-container">
              <div className="testimonial-card tc-3">
                <p className="para">
                  Would recommend who are all willing to learn Digital marketing
                  in a professional way. I went for an enquiry there, the
                  interior was awstruck. One of the most important aspects while
                  learning is good friendly environment and interior designs.
                  Those I've found that in this hashpro.. very nice guys. Keep
                  going.
                </p>
                <div className="testimonial-profile">
                  <div className="testimonial-profile-text">
                    <p style={{ color: "var(--hashpro)" }} className="name">
                      Ea incididunt{" "}
                    </p>
                    <p className="designation">Pariatur anim, laborum </p>
                  </div>
                </div>
                <img
                  loading="lazy"
                  className="quote"
                  src="./global/icons/quotation.webp"
                />
              </div>
            </div>
            <div className="testimonial-card-container">
              <div className="testimonial-card tc-4">
                <p className="para">
                  I have attended a workshop at Hashpro academy and got to learn
                  many thing's. the content and lecture was very detailed and
                  useful
                </p>
                <div className="testimonial-profile">
                  <div className="testimonial-profile-text">
                    <p style={{ color: "var(--hashpro)" }} className="name">
                      Ea incididunt{" "}
                    </p>
                    <p className="designation">Pariatur anim, laborum </p>
                  </div>
                </div>
                <img
                  loading="lazy"
                  className="quote"
                  src="./global/icons/quotation.webp"
                />
              </div>
            </div>
            <div className="testimonial-card-container">
              <div className="testimonial-card tc-5">
                <p className="para">
                  Great teaching at nominal pricing. Trainer Fahad hasan is very
                  good.
                </p>
                <div className="testimonial-profile">
                  <div className="testimonial-profile-text">
                    <p style={{ color: "var(--hashpro)" }} className="name">
                      Ea incididunt{" "}
                    </p>
                    <p className="designation">Pariatur anim, laborum </p>
                  </div>
                </div>
                <img
                  loading="lazy"
                  className="quote"
                  src="./global/icons/quotation.webp"
                />
              </div>
            </div>
            <div className="testimonial-card-container">
              <div className="testimonial-card tc-1">
                <p className="para">
                  Best graphic design institute in hyd They don't just teach
                  photoshop tool They make us understand what designing is,
                  visualisation etc Thank you Seema Mam for your wonderful
                  training
                </p>
                <div className="testimonial-profile">
                  <div className="testimonial-profile-text">
                    <p style={{ color: "var(--hashpro)" }} className="name">
                      Ea incididunt{" "}
                    </p>
                    <p className="designation">Pariatur anim, laborum </p>
                  </div>
                </div>
                <img
                  loading="lazy"
                  className="quote"
                  src="./global/icons/quotation.webp"
                />
              </div>
            </div>
            <div className="testimonial-card-container">
              <div className="testimonial-card tc-2">
                <p className="para">
                  This ain't yet another coaching institute. What sets hashpro
                  academy apart from other institutes is their Mentorship and
                  they do the teaching from heart. Great workshops and
                  affordable Courses 👍🏼. The faculty and mentors are experts in
                  their fields. Your goto place for any digital marketing needs.
                </p>
                <div className="testimonial-profile">
                  <div className="testimonial-profile-text">
                    <p style={{ color: "var(--hashpro)" }} className="name">
                      Ea incididunt{" "}
                    </p>
                    <p className="designation">Pariatur anim, laborum </p>
                  </div>
                </div>
                <img
                  loading="lazy"
                  className="quote"
                  src="./global/icons/quotation.webp"
                />
              </div>
            </div>
            <div className="testimonial-card-container">
              <div className="testimonial-card tc-3">
                <p className="para">
                  Would recommend who are all willing to learn Digital marketing
                  in a professional way. I went for an enquiry there, the
                  interior was awstruck. One of the most important aspects while
                  learning is good friendly environment and interior designs.
                  Those I've found that in this hashpro.. very nice guys. Keep
                  going.
                </p>
                <div className="testimonial-profile">
                  <div className="testimonial-profile-text">
                    <p style={{ color: "var(--hashpro)" }} className="name">
                      Ea incididunt{" "}
                    </p>
                    <p className="designation">Pariatur anim, laborum </p>
                  </div>
                </div>
                <img
                  loading="lazy"
                  className="quote"
                  src="./global/icons/quotation.webp"
                />
              </div>
            </div>
            <div className="testimonial-card-container">
              <div className="testimonial-card tc-4">
                <p className="para">
                  I have attended a workshop at Hashpro Academy and got to learn
                  many thing's. the content and lecture was very detailed and
                  useful
                </p>
                <div className="testimonial-profile">
                  <div className="testimonial-profile-text">
                    <p style={{ color: "var(--hashpro)" }} className="name">
                      Ea incididunt{" "}
                    </p>
                    <p className="designation">Pariatur anim, laborum </p>
                  </div>
                </div>
                <img
                  loading="lazy"
                  className="quote"
                  src="./global/icons/quotation.webp"
                />
              </div>
            </div>
            <div className="testimonial-card-container">
              <div className="testimonial-card tc-5">
                <p className="para">
                  Great teaching at nominal pricing. Trainer Fahad hasan is very
                  good.
                </p>
                <div className="testimonial-profile">
                  <div className="testimonial-profile-text">
                    <p style={{ color: "var(--hashpro)" }} className="name">
                      Ea incididunt{" "}
                    </p>
                    <p className="designation">Pariatur anim, laborum </p>
                  </div>
                </div>
                <img
                  loading="lazy"
                  className="quote"
                  src="./global/icons/quotation.webp"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="instagram bg-black-grain">
          <div className="instagram-container ">
            <h1 className="heading flex gap-x-2">
              Life at{" "}
              <img
                className="ml-2"
                loading="lazy"
                src="./global/logos/HashProAcademy.png"
              />
            </h1>
            <a
              href="https://www.instagram.com/thehashproacademy/"
              target="_blank"
            >
              <div className="instagram-follow-button">
                <p>Follow on Instagram</p>
                <img
                  loading="lazy"
                  width="30px"
                  src="./global/icons/instagram.webp"
                />
              </div>
            </a>
            <div className="splide instagram-videos">
              <Splide
                options={{
                  perPage: 4,
                  rewind: true,
                  autoplay: true,
                  interval: 2000,
                  breakpoints: {
                    650: {
                      perPage: 1,
                    },
                    870: {
                      perPage: 2,
                    },
                    1120: {
                      perPage: 3,
                    },
                  },
                }}
              >
                <SplideSlide>
                  <a
                    className="instagram-video-anchor"
                    href="https://www.instagram.com/p/C8zLfWlJ4LA/"
                    target="_blank"
                  >
                    <div id="insta-vid-2" className="instagram-video-container">
                      <img
                        loading="lazy"
                        className="thumbnail"
                        src="https://firebasestorage.googleapis.com/v0/b/hashpro-academy-e69e8.appspot.com/o/THE%20VK%20CLASS.jpg?alt=media&token=33f50682-5954-4bd3-9c72-a8839134c300"
                      />
                      <div className="insta-play-btn-border">
                        <img
                          loading="lazy"
                          className="insta-play-btn"
                          src="./global/icons/instagram.webp"
                        />
                      </div>
                      <div className="reel-count">
                        <svg
                          aria-label="Play count icon"
                          className="x1lliihq x1n2onr6"
                          color="rgb(255, 255, 255)"
                          fill="rgb(255, 255, 255)"
                          height={16}
                          role="img"
                          viewBox="0 0 24 24"
                          width={16}
                        >
                          <title>Play count icon</title>
                          <path d="M5.888 22.5a3.46 3.46 0 0 1-1.721-.46l-.003-.002a3.451 3.451 0 0 1-1.72-2.982V4.943a3.445 3.445 0 0 1 5.163-2.987l12.226 7.059a3.444 3.444 0 0 1-.001 5.967l-12.22 7.056a3.462 3.462 0 0 1-1.724.462Z"></path>
                        </svg>
                        <span>103K</span>
                      </div>
                    </div>
                  </a>
                </SplideSlide>
                <SplideSlide>
                  <a
                    className="instagram-video-anchor"
                    href="https://www.instagram.com/p/C9zjSY4yFE4/"
                    target="_blank"
                  >
                    <div id="insta-vid-2" className="instagram-video-container">
                      <img
                        loading="lazy"
                        className="thumbnail"
                        src="https://firebasestorage.googleapis.com/v0/b/hashpro-academy-e69e8.appspot.com/o/T%20WORKS2.jpg?alt=media&token=86685cac-0873-4d20-8bf5-6570ad39c36c"
                      />
                      <div className="insta-play-btn-border">
                        <img
                          loading="lazy"
                          className="insta-play-btn"
                          src="./global/icons/instagram.webp"
                        />
                      </div>
                      <div className="reel-count">
                        <svg
                          aria-label="Play count icon"
                          className="x1lliihq x1n2onr6"
                          color="rgb(255, 255, 255)"
                          fill="rgb(255, 255, 255)"
                          height={16}
                          role="img"
                          viewBox="0 0 24 24"
                          width={16}
                        >
                          <title>Play count icon</title>
                          <path d="M5.888 22.5a3.46 3.46 0 0 1-1.721-.46l-.003-.002a3.451 3.451 0 0 1-1.72-2.982V4.943a3.445 3.445 0 0 1 5.163-2.987l12.226 7.059a3.444 3.444 0 0 1-.001 5.967l-12.22 7.056a3.462 3.462 0 0 1-1.724.462Z"></path>
                        </svg>
                        <span>52.7K</span>
                      </div>
                    </div>
                  </a>
                </SplideSlide>
                <SplideSlide>
                  <a
                    className="instagram-video-anchor"
                    href="https://www.instagram.com/p/C7mEfWnpnA1/"
                    target="_blank"
                  >
                    <div id="insta-vid-2" className="instagram-video-container">
                      <img
                        loading="lazy"
                        className="thumbnail"
                        src="https://firebasestorage.googleapis.com/v0/b/hashpro-academy-e69e8.appspot.com/o/SALE%20SPITCH%20copy.jpg?alt=media&token=76459517-0675-4210-8487-f3bce04ade05"
                      />
                      <div className="insta-play-btn-border">
                        <img
                          loading="lazy"
                          className="insta-play-btn"
                          src="./global/icons/instagram.webp"
                        />
                      </div>
                      <div className="reel-count">
                        <svg
                          aria-label="Play count icon"
                          className="x1lliihq x1n2onr6"
                          color="rgb(255, 255, 255)"
                          fill="rgb(255, 255, 255)"
                          height={16}
                          role="img"
                          viewBox="0 0 24 24"
                          width={16}
                        >
                          <title>Play count icon</title>
                          <path d="M5.888 22.5a3.46 3.46 0 0 1-1.721-.46l-.003-.002a3.451 3.451 0 0 1-1.72-2.982V4.943a3.445 3.445 0 0 1 5.163-2.987l12.226 7.059a3.444 3.444 0 0 1-.001 5.967l-12.22 7.056a3.462 3.462 0 0 1-1.724.462Z"></path>
                        </svg>
                        <span>121K</span>
                      </div>
                    </div>
                  </a>
                </SplideSlide>
                <SplideSlide>
                  <a
                    className="instagram-video-anchor"
                    href="https://www.instagram.com/p/C7eRhd3JBxa/"
                    target="_blank"
                  >
                    <div id="insta-vid-2" className="instagram-video-container">
                      <img
                        loading="lazy"
                        className="thumbnail"
                        src="https://firebasestorage.googleapis.com/v0/b/hashpro-academy-e69e8.appspot.com/o/DMX%20DEMO.jpg?alt=media&token=f0ec2db2-dcbb-4726-b7bd-2c943ad53b03"
                      />
                      <div className="insta-play-btn-border">
                        <img
                          loading="lazy"
                          className="insta-play-btn"
                          src="./global/icons/instagram.webp"
                        />
                      </div>
                      <div className="reel-count">
                        <svg
                          aria-label="Play count icon"
                          className="x1lliihq x1n2onr6"
                          color="rgb(255, 255, 255)"
                          fill="rgb(255, 255, 255)"
                          height={16}
                          role="img"
                          viewBox="0 0 24 24"
                          width={16}
                        >
                          <title>Play count icon</title>
                          <path d="M5.888 22.5a3.46 3.46 0 0 1-1.721-.46l-.003-.002a3.451 3.451 0 0 1-1.72-2.982V4.943a3.445 3.445 0 0 1 5.163-2.987l12.226 7.059a3.444 3.444 0 0 1-.001 5.967l-12.22 7.056a3.462 3.462 0 0 1-1.724.462Z"></path>
                        </svg>
                        <span>7.8K</span>
                      </div>
                    </div>
                  </a>
                </SplideSlide>
              </Splide>
            </div>
            <div className="instagram-wave" />
          </div>
        </section>

        <section id="instagram-video-viewer">
          <img
            loading="lazy"
            src="./icons/close.png"
            width="50px"
            id="insta-close-btn"
          />
          <video id="insta-video" controls="" autoPlay="" loop="" />
        </section>
        <section className="-translate-y-24" id="home-cta"></section>
        {/* style={{ backgroundImage: 'url(/global/home/images/cta-bg.png)', backgroundSize: 'cover' }} */}
        <section className="bg-hashpro bg-black-grain py-12 md:py-24 text-center">
          <div className="grid md:grid-cols-2 gap-x-32 relative">
            <div className="border grid gap-y-4  rounded-xl text-black p-4">
              {/* ✦ */}
              <p className="text-black text-3xl md:text-4xl font-semibold my-auto h-fit ">
                {" "}
                <span className=""> Take the first step towards your</span>
                <span className="text-hashpro translate-y-1 inline-block"></span>
                <br />{" "}
                <span
                  style={{ transform: "scaleY(0)" }}
                  id="home-cta-span"
                  className="overflow-hidden  whitespace-nowrap bg-white rounded p-2 py-0 inline-block mt-2 italic text-4xl"
                >
                  {" "}
                  Limitless Future
                </span>
              </p>
              {/* <p className='text-lg mt-3 font-medium'>Ad exercitation tempor anim adipisicing mollit nostrud adipisicing id.</p> */}
              {/* <button className="black m-auto mt-4">Enroll Now</button> */}
            </div>
            <img
              className="mix-blend-difference invert w-20 py-4 pt-0 my-6 md:my-0 rotate-[35deg] md:rotate-[-35deg] mx-auto md:absolute top-1/2 left-[48%] md:-translate-x-1/2 md:-translate-y-1/2"
              style={{
                filter:
                  "invert(85%) sepia(31%) saturate(1431%) hue-rotate(353deg) brightness(100%) contrast(95%)",
              }}
              src="/global/images/string.png"
              alt=""
            />

            <div
              className="lead-form w-full"
              style={{ width: "100%", height: "fit-content", margin: "0 auto" }}
              id="lead-form"
            >
              <p className="text-lg text-black font-semibold mt-2">
                Get in Touch With Our Team
              </p>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form
                    id="contact-form"
                    className="dark inputs-container flex flex-col gap-y-4"
                  >
                    <div>
                      <Field
                        id="name"
                        className="border-black"
                        placeholder="Enter Your Name"
                        type="text"
                        name="name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div>
                      <Field
                        id="email"
                        placeholder="Enter Your Email"
                        type="email"
                        name="email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div>
                      <Field
                        id="phone"
                        placeholder="Enter Your Phone No. "
                        type="tel"
                        name="phone"
                      />
                      <span className=" text-yellow-500 p-2 m-2">
                        {" "}
                        *Preferably WhatsApp Number
                      </span>
                      <ErrorMessage
                        name="phone"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div>
                      <Field
                        as="select"
                        id="purpose"
                        name="purpose"
                        placeholder="Select Your Purpose"
                      >
                        <option selected value="" hidden>
                          Select Your Purpose
                        </option>
                        <option value="Digital Marketing Xperiential Bootcamp">
                          Digital Marketing Xperiential Bootcamp
                        </option>
                        <option value="Art Of Influence">
                          Art Of Influence
                        </option>
                      </Field>
                      <ErrorMessage
                        name="purpose"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div>
                      <Field
                        id="location"
                        placeholder="Enter Your Location"
                        type="text"
                        name="location"
                      />
                      <ErrorMessage
                        name="location"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div className="center">
                      <button
                        type="submit"
                        className="black submit-btn w-full"
                        disabled={
                          isSubmitting ||
                          formStatus == "loading" ||
                          formStatus == "submitted"
                        }
                      >
                        {formStatus === "loading" ? (
                          <Loader />
                        ) : formStatus === "failed" ? (
                          "Failed, Submit Again"
                        ) : formStatus === "submitted" ? (
                          "Form Has Been Submitted, Our Team May Get In Touch With You Soon"
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>

                    <ErrorMessage
                      name="general"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
